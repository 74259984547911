import Vue from 'vue'
import { on } from 'dom-event'
import { MOBILE_BREAKPOINT } from '~/lib/consts'
import '../newsletter-form/newsletter-form.vue'
import '../picture/picture.vue'

export default el => new Vue({
  name: 'Footer',
  el,
  data() {
    return {
      isMobile: window.innerWidth < MOBILE_BREAKPOINT,
    }
  },
  mounted() {
    this.setFooterMaxHeight()
    this.calculateOnWindowResize()
  },
  methods: {
    calculateOnWindowResize() {
      let windowWidth = window.innerWidth
      const onWindowChange = () => {
        if (window.innerWidth !== windowWidth) {
          windowWidth = window.innerWidth
          setTimeout(() => {
            this.setFooterMaxHeight()
          })
        }

        this.isMobile = window.innerWidth < MOBILE_BREAKPOINT
      }
      on(window, 'resize', onWindowChange)
      on(window, 'orientationchange', onWindowChange)
    },
    setFooterMaxHeight() {
      const { footer, footerTop, footerMiddle, footerBottom } = this.$refs
      if (footer && footerTop && footerMiddle && footerBottom) {
        const footerMaxHeight = footerMiddle.clientHeight + footerBottom.clientHeight + 1
        const headerEl = document.querySelector('.js-header')

        footer.style.maxHeight = headerEl
          ? `${window.outerHeight - headerEl.clientHeight}px`
          : '100vh'
        footerTop.style.maxHeight = headerEl
          ? `${window.outerHeight - footerMaxHeight - headerEl.clientHeight}px`
          : `${window.outerHeight - footerMaxHeight}px`
      }
    }
  },
})